
import { defineComponent } from "vue";
import axios from "axios";
//import HelloWorld from "@/components/HelloWorld.vue"; // @ is an alias to /src

export default defineComponent({
  name: "HomeView",
  components: {},

  data: function () {
    return {
      YOU_ARE_ONLINE: true,
      parametersAreSetCorrect: false,
      checkedParameters: false,
      errorWhileChecking: false,
      checkingErrorCode: "",
      inquiryId: "",
      accessToken: "",
      errorCodeFromBackend: 0,

      selectedCountry: 0,
      responseFromBackend: {
        state: 0,
        enumCountry: [],
        enumCitizentype: [],
        enumCivilStatus: [],

        employee: {
          firstName: "",
          lastName: "",

          homephone: "",
          mobilephone: "",

          address: "",
          postalCode: "",
          city: "",

          bankAccountHolder: "",
          iban: "",
          bic: "",

          countryId: 0,
          citizenshipId: 0,
          citizenTypeId: 0,
          civilStatusId: 0,
        },
        requestFirstName: false,
        requestLastName: false,
        requestDob: false,
        requestSocialsecurity: false,

        requestAddress: false,
        requestPostalCode: false,
        requestCity: false,
        requestCountryId: false,

        requestHomephone: false,
        requestMobilephone: false,

        requestBankAccountHolder: false,
        requestIban: false,
        requestBic: false,

        progressCurrent: 20,
      },
    };
  },

  mounted: function () {
    this.SetupOfflineListeners();

    var url_string = window.location.href;
    var url = new URL(url_string);
    this.inquiryId = url.searchParams.get("id") ?? "";
    this.accessToken = url.searchParams.get("token") ?? "";

    this.parametersAreSetCorrect =
      this.inquiryId.length === 32 && this.accessToken.length === 32;

    if (this.parametersAreSetCorrect) {
      this.CheckTokenAndSetupForm();
    }
  },
  computed: {
    hasRequestBlock1(): boolean {
      return (
        this.responseFromBackend.requestFirstName ||
        this.responseFromBackend.requestLastName ||
        this.responseFromBackend.requestDob ||
        this.responseFromBackend.requestSocialsecurity
      );
    },
    hasRequestBlock2(): boolean {
      return (
        this.responseFromBackend.requestAddress ||
        this.responseFromBackend.requestPostalCode ||
        this.responseFromBackend.requestCity ||
        this.responseFromBackend.requestCountryId
      );
    },
    hasRequestBlockContactInfo(): boolean {
      return (
        this.responseFromBackend.requestHomephone ||
        this.responseFromBackend.requestMobilephone
      );
    },
    hasRequestBlockBankInfo(): boolean {
      return (
        this.responseFromBackend.requestBankAccountHolder ||
        this.responseFromBackend.requestIban ||
        this.responseFromBackend.requestBic
      );
    },
  },
  methods: {
    SetupOfflineListeners: function () {
      window.addEventListener(
        "load",
        () => {
          if (navigator.onLine) {
            this.YOU_ARE_ONLINE = true;
          } else {
            this.YOU_ARE_ONLINE = false;
          }
        },
        false
      );
      window.addEventListener(
        "online",
        () => {
          this.YOU_ARE_ONLINE = true;
        },
        false
      );
      window.addEventListener(
        "offline",
        () => {
          this.YOU_ARE_ONLINE = false;
        },
        false
      );
    },
    CheckTokenAndSetupForm: function () {
      let url =
        "/api/datainquiry/loadForm/?id=" +
        this.inquiryId +
        "&token=" +
        this.accessToken;
      axios({
        method: "get",
        headers: {},
        url: url,
      })
        .then((response) => {
          console.log(response.data.data);
          if (response && response.data && response.data.data) {
            this.responseFromBackend = response.data.data;
          }
          this.errorWhileChecking = false;
          this.checkedParameters = true;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.data
          ) {
            this.errorCodeFromBackend = error.response.data.data;
          }
          this.errorWhileChecking = true;
          this.checkedParameters = true;
        });
    },
    SendInquiryToBackend: function () {
      //ToDo - Set loading states and do propper error handling
      let url =
        "/api/datainquiry/saveForm?id=" +
        this.inquiryId +
        "&token=" +
        this.accessToken;
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        url: url,
        data: this.responseFromBackend.employee,
      })
        .then((response) => {
          this.errorWhileChecking = false;
          this.checkedParameters = false;
          this.CheckTokenAndSetupForm();
        })
        .catch((error) => {
          this.errorWhileChecking = false;
          this.checkedParameters = false;
          this.CheckTokenAndSetupForm();
        });
    },
  },
});
